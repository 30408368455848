import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Kiji from "@components/kiji-link";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <section className='honbun'>
      <p><strong parentName="p">{`補足：`}</strong></p>
      <p>{`後半、ボーカルの8ｋをブーストするときにシェルフを使っているのは、シェルフの方がピークよりも音色の変化がわかりやすいからです。曲全体にかけるEQは変化が聞き取りやすいですが、その中の１つの楽器にかけると変化がわかりづらく、しかも高音はモニタスピーカーによってはきちんと再生されない場合があるので、このようにしています。`}<br parentName="p"></br>{`
`}{`また、ボーカルの8ｋ以上をEQするときは、シェルフを使った方がうまいくいくことが多いのも理由の１つです。`}</p>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      